import { Component } from 'react';
import {
  Bar,
  BarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  // Cell,
  Legend,
  CartesianGrid,
  Rectangle,
  TooltipProps,
} from 'recharts';
import Widget from '@src/components/Widget/index';
// import DatePickerComponent from '@src/packages/pro-component/components/DatePicker';
import { Form, Button, DatePicker, ConfigProvider } from 'antd';
import { helper } from '@src/controls/controlHelper';
import Loader from '@src/components/Loading';
import { RangeValue } from 'rc-picker/lib/interface';
import moment from 'moment';
const { RangePicker } = DatePicker;

export interface ReportByFactoryProps {
  location?: any;
}

export interface ReportByFactoryState {
  error?: any;
  loading?: boolean;
  data: any;
  dateRange: [moment.Moment, moment.Moment] | null;
}

const formatVnd = (d: any) => {
  return d.toLocaleString('vi-VN', {
    style: 'currency',
    currency: 'VND',
  });
};

const CustomTooltip: React.FC<TooltipProps<any, string>> = ({
  active,
  payload,
  label,
}) => {
  if (active && payload && payload.length) {
    const item = payload[0].payload;
    return (
      <div
        className='custom-tooltip'
        style={{
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          padding: '12px',
          border: '1px solid #ccc',
          borderRadius: '4px',
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        }}
      >
        <p style={{ margin: '0 0 8px', fontWeight: 'bold', color: '#333' }}>
          {`Thống kê ${label}`}
        </p>
        <p style={{ margin: '4px 0', color: '#8884d8' }}>
          Tổng tiền: {formatVnd(item.customerAmount)}
        </p>
        <p style={{ margin: '4px 0', color: '#82ca9d' }}>
          Số lượng mua: {item.customerPay}
        </p>
      </div>
    );
  }

  return null;
};

export default class ReportByFactory extends Component<
  ReportByFactoryProps,
  ReportByFactoryState
> {
  constructor(props: ReportByFactoryProps) {
    super(props);

    this.state = {
      error: null,
      loading: true,
      data: null,
      dateRange: [moment().startOf('month'), moment().endOf('day')],
    };
  }

  componentDidMount() {
    this.init();
  }

  async init() {
    const dataDash = await this.requestData();
    this.setState({
      loading: false,
      data: dataDash,
    });
  }

  async requestData() {
    const [fromDate, toDate] = this.state.dateRange || [];
    const dataDash = await helper.callPublicApi(
      'dashboard-pay',
      '/api/admin/dashboard/pay',
      'POST',
      {
        fromDate: fromDate?.valueOf(),
        toDate: toDate?.valueOf(),
      }
    );
    return dataDash;
  }

  handleDateRangeChange = (dates: [moment.Moment, moment.Moment] | null) => {
    this.setState({ dateRange: dates });
  };

  handleReset = () => {
    this.setState(
      {
        dateRange: [moment().startOf('month'), moment().endOf('day')],
      },
      () => {
        this.init();
      }
    );
  };

  handleSubmit = async () => {
    this.init();
  };

  render() {
    const { data, dateRange } = this.state;
    if (!data) return <Loader />;

    return (
      <Widget>
        <div className='gx-dealclose-header'>
          <div>
            <h2 className='h2 gx-mb-2'>
              Thống kê lượt mua/doanh thu theo ngày
            </h2>
          </div>
          <div className='gx-dealclose-header-right'>
            <Form
              layout='inline'
              className='gx-form-inline-label-up gx-form-inline-currency'
            >
              <Form.Item
                label='Chọn ngày'
                className='gx-form-item-three-fourth'
              >
                <RangePicker
                  value={dateRange as [moment.Moment, moment.Moment] | null}
                  onChange={(dates: RangeValue<moment.Moment>) => {
                    this.handleDateRangeChange(
                      dates as [moment.Moment, moment.Moment] | null
                    );
                  }}
                  format='DD/MM/YYYY'
                />
              </Form.Item>
              <Form.Item className='gx-d-block gx-mb-1'>
                <Button
                  className='gx-mb-0'
                  type='primary'
                  onClick={this.handleSubmit}
                >
                  Áp dụng
                </Button>
                <Button className='gx-mb-0' onClick={this.handleReset}>
                  Đặt lại
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
        <ResponsiveContainer width='100%' height={550}>
          <BarChart
            data={data?.data}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray='3 3' stroke='#f0f0f0' />
            <XAxis dataKey='createdAt' tick={{ fill: '#666' }} />
            <YAxis
              yAxisId='left'
              orientation='left'
              stroke='#8884d8'
              tickFormatter={(value) => formatVnd(value)}
            />
            <YAxis yAxisId='right' orientation='right' stroke='#82ca9d' />
            <Tooltip content={<CustomTooltip />} />
            <Legend wrapperStyle={{ paddingTop: '20px' }} />
            <Bar
              yAxisId='left'
              dataKey='customerAmount'
              name='Tổng tiền'
              fill='#8884d8'
              radius={[4, 4, 0, 0]}
              activeBar={<Rectangle fill='#7c79c7' stroke='#8884d8' />}
            />
            <Bar
              yAxisId='right'
              dataKey='customerPay'
              name='Số lượng mua'
              fill='#82ca9d'
              radius={[4, 4, 0, 0]}
              activeBar={<Rectangle fill='#6eb88d' stroke='#82ca9d' />}
            />
          </BarChart>
        </ResponsiveContainer>
      </Widget>
    );
  }
}
